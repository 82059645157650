import React from 'react';
import Layout from 'components/layout';
import SeoComponent from 'components/seo';
import {Breadcrumb, Button, LineChart, StatisticsHexagonMap} from "components/ui";
import interactiveStatisticsCharts from 'data/interactive-statistics-series-data.js';
import StatisticsData1 from 'data/data-1-prevalence-aod-removal.js';
import StatisticsData2 from 'data/data-2-parental-aod-removal-by-state';
import usStateRPGMatrix from "data/rpg-map-colors.js";
import StatisticsMapLegend from 'assets/images/statistics-map-legend.inline.svg';
import stateMap from 'data/state-map';

const isBrowser = typeof window !== "undefined"
const StatisticsPage2=({location})=>{
    let thisLocationPathname = ''
    if (isBrowser) {
        thisLocationPathname = window.location.pathname;
    }
    const pageData = interactiveStatisticsCharts.filter(data=>data.id === 1 || data.id === 4)
    const interactiveStatisticsPageData1 = pageData[0];
    const interactiveStatisticsPageData2 = pageData[1];
    const seo = {
        metaTitle: interactiveStatisticsPageData1.title,
        metaDescription: `${interactiveStatisticsPageData1.metaDescription}\n${interactiveStatisticsPageData2.metaDescription}`,
    }
    const sortMap=data=>{
        let sortedMap=data.sort((currentState,state)=>stateMap.indexOf(currentState?.abbr)-stateMap.indexOf(state?.abbr))
        return sortedMap;
    }
    return (
        <Layout location={location}>
            <SeoComponent title={seo.metaTitle} description={seo.metaDescription} />
            <div className="grid-container padding-bottom-5">
                <Breadcrumb pathname={thisLocationPathname} customCurrentPage={seo.metaTitle} />
                <h1 name="main-content" id="main-content">{interactiveStatisticsPageData1.title}</h1>
                <h2>{interactiveStatisticsPageData1.subTitle}</h2>
                <div className="desktop:padding-bottom-5">
                    <div className="grid-row">
                        <div className="grid-col-12">
                            <p><strong>Source:</strong> {interactiveStatisticsPageData1.author}, {interactiveStatisticsPageData1.year}</p>
                        </div>
                    </div>
                </div>
                <div className="padding-bottom-5">
                    <LineChart
                    title="Percentage"
                    chartLabels={[...StatisticsData1.map(data=>data.xAxis)]}
                    chartData={[...StatisticsData1.map(data=>data.yAxis)]}
                    xAxisLabel="Year"
                    yAxisLabel="Percentage"
                    backgroundColor="#383E56"
                    borderColor="#383E56"
                    yAxisMax={45}
                    isPercent={true}
                    />
                    <p>
                        The percentage of children in out-of-home care with parental alcohol or other drug (AOD) abuse as a condition associated with removal has steadily increased from 18.5% in 2000 to 39.1% in 2021.
                    </p>
                    <p><strong>Note:</strong> Estimates based on <strong className="text-underline">all children in out of home care at some point</strong> during the Fiscal Year </p>
                    <Button color="primary-button" type="button" size="padding-x-5" link={`/files/${interactiveStatisticsPageData1.fileName}`} labelText={`Learn more About ${interactiveStatisticsPageData1.title}`}>
                        View Source Data (PDF {interactiveStatisticsPageData1.fileSize})
                    </Button>
                    <p>Section: Policy and Practice Resources</p>
                </div>
                <h2>{interactiveStatisticsPageData2.subTitle}</h2>
                <div className="grid-row">
                    <div className="grid-col-12">
                        <p><strong>Source:</strong> {interactiveStatisticsPageData2.author}, {interactiveStatisticsPageData2.year}</p>
                        <div className="display-flex">
                            <p className="text-bold">National Average 39.1%</p>
                            <p className="text-bold margin-left-5">N=603,823</p>
                        </div>
                    </div>
                </div>
                <div>
                    <StatisticsHexagonMap 
                     usStateMatrix={usStateRPGMatrix}
                     usStateData={sortMap(StatisticsData2)}
                     mapID="parental-alcohol-or-drug-abuse-as-an-identified-condition-of-removal-for-children-under-1-year-2020-hexagon-map"
                     mapIDValue={0}
                    />
                    <div className="desktop:display-block tablet:display-none mobile:display-none">
                        <StatisticsMapLegend />
                    </div>
                    <p>
                        Nationally, 39.1% of children placed in out-of-home care had parental alcohol or other drug (AOD) abuse as a condition associated with removal in 2021. The map provides the percent by state.
                        <br />
                        <br />
                        States often anecdotally report that the percentage of child welfare removals involving parental AOD abuse is much higher in their state than indicated in the data. 
                        Possible explanations for these discrepancies may include 1) lack of child welfare protocols for screening and assessment regarding identification of substance use disorders; 2) inconsistent protocols regarding data entry for child welfare; 3) discrepancies in how AOD abuse is captured in the state child welfare’s data systems; and 4) differences in the point at which the AOD abuse is identified and entered in the data system. 
                        Often, at the local level, multiple removal reasons are reported and sometimes only the primary reason for removal is reported by the federal system(s).

                    </p>
                    <p><strong>Note:</strong> Estimates based on <strong className="text-underline">all children in out of home care at some point</strong> during the Fiscal Year </p>
                    <Button color="primary-button" type="button" size="padding-x-5" btnType="usa-button statistics-btn" link={`/files/${interactiveStatisticsPageData2.fileName}`} labelText={`Learn more About ${interactiveStatisticsPageData2.title}`}>
                        View Source Data (PDF {interactiveStatisticsPageData2.fileSize})
                    </Button>
                    <p>Section: Policy and Practice Resources</p>
                </div>
            </div>      
        </Layout>
    )
}
export default StatisticsPage2;