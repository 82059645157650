const StatisticsData2=[
    {
        id: 1,
        xAxis: "AK",
        yAxis: "67.2",
        number: "2768",
        total: "4119"
    },
    {
        id: 2,
        xAxis: "ME",
        yAxis: "51.5",
        number: "1638",
        total: "3181"
    },
    {
        id: 3,
        xAxis: "VT",
        yAxis: "31.6",
        number: "512",
        total: "1620"
    },
    {
        id: 4,
        xAxis: "NH",
        yAxis: "15.4",
        number: "260",
        total: "1688"
    },
    {
        id: 5,
        xAxis: "WA",
        yAxis: "44.5",
        number: "5944",
        total: "13354"
    },
    {
        id: 6,
        xAxis: "MT",
        yAxis: "39.4",
        number: "1984",
        total: "5036"
    },
    {
        id: 7,
        xAxis: "ND",
        yAxis: "42.6",
        number: "1053",
        total: "2470"
    },
    {
        id: 8,
        xAxis: "MN",
        yAxis: "51.2",
        number: "6187",
        total: "12084"
    },
    {
        id: 9,
        xAxis: "WI",
        yAxis: "38.2",
        number: "4049",
        total: "10586"
    },
    {
        id: 10,
        xAxis: "MI",
        yAxis: "39.3",
        number: "5693",
        total: "14474"
    },
    {
        id: 11,
        xAxis: "NY",
        yAxis: "30.9",
        number: "6181",
        total: "20005"
    },
    {
        id: 12,
        xAxis: "MA",
        yAxis: "34.5",
        number: "4685",
        total: "13594"
    },
    {
        id: 13,
        xAxis: "RI",
        yAxis: "38.6",
        number: "1113",
        total: "2882"
    },
    {
        id: 14,
        xAxis: "ID",
        yAxis: "41.9",
        number: "1130",
        total: "2698"
    },
    {
        id: 15,
        xAxis: "WY",
        yAxis: "50.8",
        number: "813",
        total: "1601"
    },
    {
        id: 16,
        xAxis: "SD",
        yAxis: "57.9",
        number: "1522",
        total: "2629"
    },
    {
        id: 17,
        xAxis: "IA",
        yAxis: "60.0",
        number: "4448",
        total: "7413"
    },
    {
        id: 18,
        xAxis: "IL",
        yAxis: "11.1",
        number: "3033",
        total: "27281"
    },
    {
        id: 19,
        xAxis: "IN",
        yAxis: "61.7",
        number: "13504",
        total: "21877"
    },
    {
        id: 20,
        xAxis: "OH",
        yAxis: "30.9",
        number: "7974",
        total: "25773"
    },
    {
        id: 21,
        xAxis: "PA",
        yAxis: "36.8",
        number: "7896",
        total: "21455"
    },
    {
        id: 22,
        xAxis: "NJ",
        yAxis: "43.6",
        number: "2303",
        total: "5288"
    },
    {
        id: 23,
        xAxis: "CT",
        yAxis: "43.4",
        number: "2189",
        total:"5041"
    },
    {
        id: 24,
        xAxis: "OR",
        yAxis: "57.3",
        number: "4747",
        total: "8281"
    },
    {
        id: 25,
        xAxis: "NV",
        yAxis: "15.9",
        number: "1133",
        total: "7139"
    },
    {
        id: 26,
        xAxis: "CO",
        yAxis: "49.9",
        number: "4063",
        total: "8147"
    },
    {
        id: 27,
        xAxis: "NE",
        yAxis: "36.4",
        number: "2003",
        total: "5506"
    },
    {
        id: 28,
        xAxis: "MO",
        yAxis: "51.3",
        number: "9924",
        total: "19333"
    },
    {
        id: 29,
        xAxis: "KY",
        yAxis: "41.6",
        number: "5678",
        total: "13647"
    },
    {
        id: 30,
        xAxis: "WV",
        yAxis: "55.2",
        number: "6574",
        total: "11911"
    },
    {
        id: 31,
        xAxis: "VA",
        yAxis: "33.8",
        number: "2562",
        total: "7572"
    },
    {
        id: 32,
        xAxis: "MD",
        yAxis: "31.2",
        number: "1715",
        total: "5493"
    },
    {
        id: 33,
        xAxis: "DE",
        yAxis: "19.6",
        number: "142",
        total: "724"
    },
    {
        id: 34,
        xAxis: "CA",
        yAxis: "11.7",
        number: "8379",
        total: "71668"
    },
    {
        id: 35,
        xAxis: "UT",
        yAxis: "61.3",
        number: "2374",
        total: "3870"
    },
    {
        id: 36,
        xAxis: "NM",
        yAxis: "43.6",
        number: "1319",
        total: "3027"
    },
    {
        id: 37,
        xAxis: "KS",
        yAxis: "35.5",
        number: "3654",
        total: "10284"
    },
    {
        id: 38,
        xAxis: "AR",
        yAxis: "50.3",
        number: "3610",
        total: "7170"
    },
    {
        id: 39,
        xAxis: "TN",
        yAxis: "40.1",
        number: "5843",
        total: "14558"
    },
    {
        id: 40,
        xAxis: "NC",
        yAxis: "44.0",
        number: "6745",
        total: "15345"
    },
    {
        id: 41,
        xAxis: "SC",
        yAxis: "17.3",
        number: "1162",
        total: "6702"
    },
    {
        id: 42,
        xAxis: "DC",
        yAxis: "15.7",
        number: "127",
        total: "810"
    },
    {
        id: 43,
        xAxis: "AZ",
        yAxis: "34.3",
        number: "7640",
        total: "22269"
    },
    {
        id: 44,
        xAxis: "OK",
        yAxis: "52.9",
        number: "6088",
        total: "11507"
    },
    {
        id: 45,
        xAxis: "LA",
        yAxis: "1.9",
        number: "109",
        total: "5852"
    },
    {
        id: 46,
        xAxis: "MS",
        yAxis: "48.0",
        number: "2668",
        total: "5554"
    },
    {
        id: 47,
        xAxis: "AL",
        yAxis: "46.8",
        number: "4250",
        total: "9082"
    },
    {
        id: 48,
        xAxis: "GA",
        yAxis: "44.7",
        number: "7161",
        total: "16032"
    },
    {
        id: 49,
        xAxis: "HI",
        yAxis: "35.4",
        number: "855",
        total:"2414"
    },
    {
        id: 50,
        xAxis: "TX",
        yAxis: "65.7",
        number: "29523",
        total: "44929"
    },
    {
        id: 51,
        xAxis: "FL",
        yAxis: "51.8",
        number: "18745",
        total: "36161"
    },
    {
        id: 52,
        xAxis: "PR",
        yAxis: "17.5",
        number: "471",
        total: "2687"
    }
]
export default StatisticsData2;