const stateMap = [
    { abbr: 'AK', name: 'Alaska'},
    { abbr: 'ME', name: 'Maine'},
    { abbr: 'VT', name: 'Vermont'},
    { abbr: 'NH', name: 'New Hampshire'},
    { abbr: 'WA', name: 'Washington'},
    { abbr: 'MT', name: 'Montana'},
    { abbr: 'ND', name: 'North Dakota'},
    { abbr: 'MN', name: 'Minnesota'},
    { abbr: 'WI', name: 'Wisconsin'},
    { abbr: 'MI', name: 'Michigan'},
    { abbr: 'NY', name: 'New York'},
    { abbr: 'MA', name: 'Massachusetts'},
    { abbr: 'RI', name: 'Rhode Island'},
    { abbr: 'ID', name: 'Idaho'},
    { abbr: 'WY', name: 'Wyoming'},
    { abbr: 'SD', name: 'South Dakota'},
    { abbr: 'IA', name: 'Iowa'},
    { abbr: 'IL', name: 'Illinois'},
    { abbr: 'IN', name: 'Indiana'},
    { abbr: 'OH', name: 'Ohio'},
    { abbr: 'PA', name: 'Pennsylvania'},
    { abbr: 'NJ', name: 'New Jersey'},
    { abbr: 'CT', name: 'Connecticut'},
    { abbr: 'OR', name: 'Oregon'},
    { abbr: 'NV', name: 'Nevada'},
    { abbr: 'CO', name: 'Colorado'},
    { abbr: 'NE', name: 'Nebraska'},
    { abbr: 'MO', name: 'Missouri' },
    { abbr: 'KY', name: 'Kentucky'},
    { abbr: 'WV', name: 'West Virgina'},
    { abbr: 'VA', name: 'Virginia'},
    { abbr: 'MD', name: 'Maryland'},
    { abbr: 'DE', name: 'Delaware'},
    { abbr: 'CA', name: 'California'},
    { abbr: 'UT', name: 'Utah'},
    { abbr: 'NM', name: 'New Mexico'},
    { abbr: 'KS', name: 'Kansas'},
    { abbr: 'AR', name: 'Arkansas'},
    { abbr: 'TN', name: 'Tennessee'},
    { abbr: 'NC', name: 'North Carolina'},
    { abbr: 'SC', name: 'South Carolina' },
    { abbr: 'DC', name: 'District of Columbia'},
    { abbr: 'AZ', name: 'Arizona'},
    { abbr: 'OK', name: 'Oklahoma'},
    { abbr: 'LA', name: 'Louisiana'},
    { abbr: 'MS', name: 'Mississippi'},
    { abbr: 'AL', name: 'Alabama'},
    { abbr: 'GA', name: 'Georgia'},
    { abbr: 'HI', name: 'Hawaii'},
    { abbr: 'TX', name: 'Texas',},
    { abbr: 'FL', name: 'Florida'},
    { abbr: 'PR', name: 'Puerto Rico'},
    ];
export default stateMap;